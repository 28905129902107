<template>
  <div class="box">
    <div class="select" v-if="companyList.length != 0">
      <van-dropdown-menu active-color="#0084FF">
        <van-dropdown-item
          v-model="company"
          :options="companyList"
          @change="changeCompany"
        />
      </van-dropdown-menu>
    </div>
    <van-cell-group style="margin-top: 15px" v-if="matchList.length > 0">
      <van-cell
        is-link
        @click="toInfo(item.loanPredictionId, item.gmtCreate2, item.companyId)"
        v-for="item in matchList"
        :key="item.companyId"
      >
        <div class="label">
          <van-icon class="iconfont icon-ce"></van-icon>
          <div class="time">{{ item.gmtCreate2 }}</div>
          <div class="name">{{ item.companyName }}</div>
        </div>
      </van-cell>
    </van-cell-group>
    <van-empty
      v-if="matchList.length == 0"
      class="custom-image"
      :image="noData"
      description="暂无测额产品"
    />
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      company: "",
      companyList: [],
      matchList: [],
      noData: require("@/img/no-data.png"),
    };
  },
  created() {
    this.getCompanyList();
  },
  methods: {
    //获取企业列表
    getCompanyList() {
      // this.$http.get(url.companyList).then((res) => {
      //   res.data.forEach((v) => {
      //     this.companyList.push({
      //       text: v.name,
      //       value: v.companyId,
      //     });
      //   });
      //   this.company = this.companyList[0].value;
      //   this.getMatchList(this.company);
      // });

      this.$http.get(url.companyList2).then((res) => {
        res.data.forEach((v) => {
          this.companyList.push({
            text: v.companyName,
            value: v.companyId,
          });
        });
        this.company = this.companyList[0].value;
        this.getMatchList(this.company);
      });
    },
    //获取匹配记录
    getMatchList(companyId) {
      this.$http
        .get(url.getMatchList + `?companyId=${companyId}`)
        .then((res) => {
          this.matchList = res.data;
        });
    },
    //切换企业
    changeCompany(val) {
      this.getMatchList(val);
    },
    toInfo(loanPredictionId, gmtCreate2, companyId) {
      //老版
      // this.$router.push({
      //   path: "matchDetail",
      //   query: {
      //     loanPredictionId: loanPredictionId,
      //     time: gmtCreate2,
      //     companyId: companyId,
      //   },
      // });

      //新版
      this.$router.push({
        path: "pipeiChanPin",
        query: {
          loanPredictionId: loanPredictionId,
          time: gmtCreate2,
          companyId: companyId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f4f5f9;
  // min-height: calc(100vh - 80px);
  // padding-bottom: 30px;
  padding-top: 56px;
  min-height: calc(100vh - 56px);
  .label {
    display: flex;
    align-items: center;
    .van-icon {
      color: #bdbdbd;
    }
  }
  .time,
  .name {
    width: 42%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 10px;
  }
}
</style>
